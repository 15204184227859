<template>
    <div class="manage_project wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" class="demo-form-inline flex-align-between">
            <div class="formInline-left">
            </div>
            <div class="formInline-right">
                <i class="el-icon-circle-plus-outline"></i>
                <span class="csp" @click="addTeam">添加成员</span>
            </div>
        </el-form>
        <div class="mid-bot">
            <el-table :data="tableData">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column prop="name" label="姓名" align="center"></el-table-column>
                <el-table-column prop="job" label="职务" align="center"></el-table-column>
                <el-table-column prop="title" label="职称" align="center"></el-table-column>
                <el-table-column prop="intro" label="简介" align="center"></el-table-column>
                <el-table-column label="头像" align="center">
                    <template slot-scope="scope">
                        <img :src="downloadURL + scope.row.cover" alt="" class="avatar" v-if="scope.row.cover">
                        <span v-else>暂无</span>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="操作" fixed="right" width="180px" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="Edit(scope.row.id)">编辑</el-button>
                        <el-button type="text" size="small" @click="isdelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination v-show="total > 0" :total="total" :page.sync="page" :limit.sync="limit" :pageSizes="[5, 10, 15]"
                @pagination="handlePageChange">
            </pagination>
        </div>
        <!-- 添加成员弹窗 -->
        <el-dialog title="添加用户" :visible.sync="dialogFormVisible">
            <el-form :model="form" label-position="right" :rules="rules" ref="ruleForm">
                <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="form.name" autocomplete="off" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="职务" :label-width="formLabelWidth">
                    <el-input v-model="form.job" autocomplete="off" placeholder="请输入职务"></el-input>
                </el-form-item>
                <el-form-item label="职称" :label-width="formLabelWidth">
                    <el-input v-model="form.title" autocomplete="off" placeholder="请输入职称"></el-input>
                </el-form-item>
                <el-form-item label="团队角色" :label-width="formLabelWidth">
                    <el-select v-model="form.region" placeholder="请选择活动区域">
                        <el-option :label="item.dictLabel" :value="item.dictValue" v-for="(item, index) in teamOption"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="教师简介" :label-width="formLabelWidth">
                    <el-input type="textarea" class="textarea" :rows="6" maxlength="500" show-word-limit
                        v-model="form.intro" resize="none"></el-input>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth">
                    <UploadCover @uploadCover="handleAvatarChange" :xcover="form.cover"></UploadCover>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import {
    mapState
} from "vuex";
export default {
    data() {
        return {
            tableData: [],
            page: 1,
            limit: 10,
            total: 0,
            dialogFormVisible: false,
            form: {
                name: '',
                region: '',
                job: '',
                title: '',
                intro: '',
                cover: ''
            },
            formLabelWidth: '80px',
            teamOption: [],
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
            },
            isEdit: false,
            editId: '',
        };
    },
    computed: {
        ...mapState({
            currentProject: state => state.user.currentProject,
        })
    },
    methods: {
        // 翻页
        handlePageChange(data) {
            this.page = data.pageIndex
            this.limit = data.pageSize
            this.listForAdmin()
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        async listForAdmin() {
            let data = {
                page: this.page,
                limit: this.limit,
                projectId: this.currentProject.id
            }
            let resData = await this.$Api.DST.listByAdmin(data)
            console.log('团队列表:', resData);
            this.tableData = resData.data.records;
            this.total = resData.data.total
        },
        // 删除提示
        isdelete(id) {
            this.$confirm("确定删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let data = {
                    id
                }
                this.$Api.DST.delete(data)
                    .then(res => {
                        // console.log('删除成功:',res);
                        this.listForAdmin()
                    })
                    .catch(err => {
                        console.log('删除失败:', err)
                    })
            })
                .catch((err) => {
                    console.log('err:', err)
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        addTeam() {
            this.form = {
                name: '',
                region: '',
                job: '',
                title: '',
                intro: '',
                cover: ''
            }
            this.isEdit = false;
            this.dialogFormVisible = true;
        },
        handleAvatarChange(data) {
            console.log('头像MD5:', data);
            this.form.cover = data.md5
        },
        async getCreateOption() {
            let resData = await this.$Api.DST.getCreateOption();
            // console.log('团队角色:',resData);
            this.teamOption = resData.data
        },
        submit() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    let data = {
                        type: this.form.region,
                        name: this.form.name.trim(),
                        job: this.form.job,
                        title: this.form.title,
                        intro: this.form.intro,
                        cover: this.form.cover,
                        projectId: this.currentProject.id
                    }
                    if (this.isEdit) {
                        data.id = this.editId;
                        this.$Api.DST.edit(data)
                            .then(res => {
                                // console.log('创建成功:', res);
                                this.$notify({
                                    title: '成功',
                                    message: '编辑成功',
                                    type: 'success'
                                });

                                this.dialogFormVisible = false;
                                this.listForAdmin();

                            })
                            .catch(err => {
                                console.log('编辑失败:', err);
                                this.$notify.error({
                                    title: '错误',
                                    message: '创建失败,请联系管理员'
                                });
                            })
                    } else {
                        this.$Api.DST.create(data)
                            .then(res => {
                                // console.log('创建成功:', res);
                                this.$notify({
                                    title: '成功',
                                    message: '创建成功',
                                    type: 'success'
                                });

                                this.dialogFormVisible = false;
                                this.listForAdmin();
                            })
                            .catch(err => {
                                console.log('创建失败:', err);
                                this.$notify.error({
                                    title: '错误',
                                    message: '创建失败,请联系管理员'
                                });
                            })
                    }

                } else {
                    console.log('error submit!!');
                    this.$notify.error({
                        title: '错误',
                        message: '请检查必填项'
                    });
                    return false;
                }
            });

        },
        async Edit(id) {
            // console.log('编辑id:', id);
            this.form = {
                name: '',
                region: '',
                job: '',
                title: '',
                intro: '',
                cover: ''
            }
            this.isEdit = true;
            this.editId = id;
            let data = {
                id
            }
            let resData = await this.$Api.DST.one(data);
            console.log('获取单个专家信息:', resData);
            // let data = this.tableData.find(item => item.id == id);
            this.form.name = resData.data.name;
            this.form.job = resData.data.job;
            this.form.title = resData.data.title;
            this.form.intro = resData.data.intro;
            this.form.cover = resData.data.cover;
            this.form.region = String(resData.data.type);
            this.dialogFormVisible = true;
            // console.log('cover:',this.form.cover)
        }
    },
    mounted() {
        this.listForAdmin();
        this.getCreateOption()
    }
};
</script>
  
<style lang="less" scoped>
.mid-bot {
    margin-top: 20px;

    .avatar {
        width: 50px;
    }
}

/deep/ .el-dialog__body {
    width: 60%;
    display: flex;
    justify-content: space-around;
}

/deep/ .el-dialog__footer {
    text-align: center;
}

/deep/ .el-table .cell {
    white-space: nowrap;
}
</style>